<template>
  <div>
    <CForm @submit="submitFilterValues">
      <CCard>
        <CButton
          @click="cardCollapse = !cardCollapse"
          tag="button"
          color="link"
          class="flex flex--space-between text-left shadow-none card-header"
        >
          <h5 class="m-0">Filtros</h5>
          <CIcon :name="`cil-chevron-${cardCollapse ? 'top' : 'bottom'}`" />
        </CButton>
        <CCollapse :show="cardCollapse">
          <CCardBody>
            <CRow>
              <CCol sm="4">
                <label class="filter-label">{{ messages.filters.area }}</label>
                <FiltersAreaCombo
                  :activeElements="activeItems"
                  :all-countries="withIbr"
                />
                <span
                  v-if="areaError"
                  class="error-message error-message--bottom"
                  >{{ messages.validation.filterAreaCombo }}</span
                >
              </CCol>
              <CCol sm="4">
                <label class="filter-label">FY</label>
                <FilterFY />
              </CCol>
              <CCol sm="4">
                <label class="filter-label">{{
                  messages.filters.period
                }}</label>
                <FilterMonth />
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CButton type="submit" color="primary">Mostrar</CButton>
          </CCardFooter>
        </CCollapse>
      </CCard>
    </CForm>
    <CCard color="secondary" v-if="showBools.customValues">
      <CCardHeader>
        <h5 class="card-title">{{ messages.dailyReport.customValues }}</h5>
      </CCardHeader>
      <CCardBody>
        <div class="custom-grid--row">
          <div class="custom-grid--col">
            <SetNumberInput
              ref="numberInputChild1"
              :initValue="initValueDaysInvoiced"
              :maxValue="maxValueDaysInvoiced"
              :concept="conceptDaysInvoiced"
              type="invoiceDays"
              @reload-tables="reloadTables"
            />
          </div>
          <div class="custom-grid--col">
            <SetNumberInput
              ref="numberInputChild2"
              :initValue="initValueWorkingDays"
              :maxValue="maxValueWorkingDays"
              :concept="conceptWorkingDays"
              type="workingDays"
              @reload-tables="reloadTables"
              inputMaxLength="8"
            />
          </div>
          <div class="custom-grid--col">
            <SetCustomColHeaderInput
              ref="customColHeaderChild"
              :initValue="initValueCustomColHeader"
              :concept="conceptCustomColHeader"
              @set-custom-header="sendCustomHeader($event)"
            />
          </div>
        </div>
      </CCardBody>
    </CCard>
    <CRow>
      <CCol>
        <CCard v-if="showBools.printButtons">
          <CCardBody class="flex flex--space-between">
            <CButton color="primary" @click="printTables">
              {{ messages.tables.print }} {{ messages.tables.tables }}
            </CButton>

            <CButton
              color="light"
              class="gtm-tag-export"
              @click="exportHTMLToExcel"
            >
              {{ messages.tables.export.toUpperCase() }}
              <LogoExcel />
            </CButton>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CCard ref="printArea" id="excelArea">
      <TableFullBusiness
        :showLoader="showBools.loaderTableFullB"
        :fields="tableHeaders"
        :items="tableFullBusinessData"
        :customHeader="customHeader"
        ref="table-full-business"
      >
        <span slot="country">{{ formattedSelectedCountry }} - </span>
        <span slot="month">{{ messages.filters.months[selectedMonth] }} / </span>
        <span slot="fiscalYear">{{ formattedSelectedFY }}</span>
        <span slot="invoiceDays">{{ currentInvoiceDays }}</span>
        <span slot="workingDays">{{ currentWorkingDays }}</span>
      </TableFullBusiness>
      <TableRealBusiness
        :showLoader="showBools.loaderTableRealB"
        :fields="tableHeaders"
        :items="tableRealBusinessData"
        :customHeader="customHeader"
        ref="table-real-business"
      >
        <span slot="country">{{ formattedSelectedCountry }}</span>
        <span slot="month">{{ messages.filters.months[selectedMonth] }}</span>
        <span slot="fiscalYear">{{ formattedSelectedFY }}</span>
      </TableRealBusiness>
    </CCard>
  </div>
</template>

<script>
import Api from "@/services/Api";
import currentFY from "@/services/CurrentFY";
import formatNum from "@/utils/formatNum";
const FiltersAreaCombo = () =>
  import(
    /* webpackChunkName: "filtersAreaCombo" */ "@/components/organisms/FiltersAreaCombo"
  );
const FilterFY = () =>
  import(
    /* webpackChunkName: "filter-fiscal-year" */ "@/components/molecules/FilterFY"
  );
const FilterMonth = () =>
  import(
    /* webpackChunkName: "FilterMonth" */ "@/components/molecules/FilterMonth"
  );
const SetNumberInput = () =>
  import(
    /* webpackChunkName: "set-number-input" */ "@/components/molecules/SetNumberInput"
  );
const SetCustomColHeaderInput = () =>
  import(
    /* webpackChunkName: "set-number-input" */ "@/components/molecules/SetCustomColHeaderInput"
  );
const TableFullBusiness = () =>
  import(
    /* webpackChunkName: "table-full-business" */ "@/components/organisms/TableFullBusiness"
  );
const TableRealBusiness = () =>
  import(
    /* webpackChunkName: "table-real-business" */ "@/components/organisms/TableRealBusiness"
  );
import LogoExcel from "@/components/atoms/LogoExcel";
import ExportHTML2Excel from "@/helpers/ExportHTML2Excel";

export default {
  name: "DailyReport",
  components: {
    FiltersAreaCombo,
    FilterFY,
    FilterMonth,
    SetNumberInput,
    SetCustomColHeaderInput,
    TableFullBusiness,
    TableRealBusiness,
    LogoExcel,
  },
  data() {
    return {
      cardCollapse: true,
      activeItems: ["countries"],
      withIbr: true,
      areaError: false,
      showBools: {
        customValues: false,
        printButtons: false,
        loaderTableFullB: true,
        loaderTableRealB: true,
      },
      initValueDaysInvoiced: 0,
      maxValueDaysInvoiced: 30,
      initValueWorkingDays: 0,
      maxValueWorkingDays: 23,
      initValueCustomColHeader: "",
      selectedFY: null,
      selectedMonth: null,
      selectedCountry: null,
      customHeader: null,
      printObj: {
        id: "table",
      },
      productGroups: {
        fullBusiness: ["RB", "RC", "GT"],
        realBusiness: ["RB"],
      },
      tableHeaders: [],
      tableFullBusinessData: [],
      tableRealBusinessData: [],
      currentInvoiceDays: 0,
      currentWorkingDays: 0,
    };
  },
  computed: {
    messages() {
      return this.$store.getters.getLangMessages;
    },
    conceptDaysInvoiced() {
      return this.messages.dailyReport.daysInvoiced;
    },
    conceptWorkingDays() {
      return this.messages.dailyReport.workingDays;
    },
    conceptCustomColHeader() {
      return this.messages.dailyReport.customColumnHeader;
    },
    computedDefaults() {
      return {
        countryCode: JSON.parse(localStorage.user).countryCode,
        month: new Date().getMonth() + 1,
      };
    },
    formattedSelectedCountry() {
      if (this.selectedCountry) {
        return this.selectedCountry.charAt(0).toUpperCase() + this.selectedCountry.slice(1).toLowerCase();
      } else {
        return '';
      }
    },
    formattedSelectedFY() {
      const lastTwoDigits = this.selectedFY % 100;
      return "FY" + lastTwoDigits;
    }
  },
  methods: {
    defaultQuery() {
      this.showBools.loaderTableFullB = true;
      this.showBools.loaderTableRealB = true;
      this.showBools.printButtons = false;

      currentFY().then((currentFY) => {
        const defaultsTableRealBusiness = {
          ...this.computedDefaults,
          fiscalYear: currentFY,
          productGroups: this.productGroups.realBusiness,
        };
        const defaultsTableFullBusiness = {
          ...this.computedDefaults,
          fiscalYear: currentFY,
          productGroups: this.productGroups.fullBusiness,
        };

        this.selectedFY = currentFY;
        this.selectedMonth = this.computedDefaults.month;
        this.selectedCountry =
          this.messages.areaByCode[
            this.computedDefaults.countryCode
          ].toUpperCase();

        // Refactor
        this.makeApiQuery(defaultsTableRealBusiness).then((data) => {
          this.setInitialData(data);
          this.setTableHeaders(data);
          console.log(
            "REAL BUSINESS - data.dailyReportTableList",
            data.dailyReportTableList
          );
          this.processTableData(
            data.dailyReportTableList,
            "tableRealBusinessData"
          );
          this.showBools.loaderTableRealB = false;
          if (this.showBools.printButtons === false) {
            this.showBools.printButtons = true;
          }
        });

        // Refactor
        this.makeApiQuery(defaultsTableFullBusiness).then((data) => {
          //console.log("data.dailyReportTableList", data.dailyReportTableList);
          this.processTableData(
            data.dailyReportTableList,
            "tableFullBusinessData"
          );
          this.showBools.loaderTableFullB = false;
        });
      });
    },
    submitFilterValues(event) {
      if (event) event.preventDefault();

      this.showBools.loaderTableFullB = true;
      this.showBools.loaderTableRealB = true;
      this.showBools.printButtons = false;

      let validated = false;
      if (!this.$store.getters.getFiltersAreaComboParams) {
        this.areaError = true;
      } else {
        this.areaError = false;
        validated = true;
      }
      if (validated) {
        const commonParams = {
          ...this.$store.getters.getFiltersAreaComboParams,
          fiscalYear: this.$store.getters.getFilterFYValue,
          month: this.$store.getters.getFilterMonthValue,
        };
        // console.log(commonParams);
        this.selectedFY = commonParams.fiscalYear;
        this.selectedMonth = commonParams.month;
        this.selectedCountry =
          this.messages.areaByCode[
            this.$store.getters.getFiltersAreaComboParams.countryCode
          ];

        const paramsTableRealBusiness = {
          ...commonParams,
          productGroups: this.productGroups.realBusiness,
        };
        const paramsTableFullBusiness = {
          ...commonParams,
          productGroups: this.productGroups.fullBusiness,
        };

        // Refactor
        this.makeApiQuery(paramsTableRealBusiness).then((data) => {
          this.setInitialData(data);
          this.setTableHeaders(data);
          //console.log("data.dailyReportTableList", data.dailyReportTableList);
          this.processTableData(
            data.dailyReportTableList,
            "tableRealBusinessData"
          );
          this.showBools.loaderTableRealB = false;
          if (this.showBools.printButtons === false) {
            this.showBools.printButtons = true;
          }
          // This is the way to call a child's method
          this.$refs.numberInputChild1.resetValue(this.initValueDaysInvoiced);
          this.$refs.numberInputChild2.resetValue(this.initValueWorkingDays);
          this.$refs.customColHeaderChild.resetValue(this.customHeader);
        });

        // Refactor
        this.makeApiQuery(paramsTableFullBusiness).then((data) => {
          //console.log("data.dailyReportTableList", data.dailyReportTableList);
          this.processTableData(
            data.dailyReportTableList,
            "tableFullBusinessData"
          );
          this.showBools.loaderTableFullB = false;
        });
      }
    },
    sendCustomHeader(value) {
      const params = {
        fiscalYear: this.$store.getters.getFilterFYValue,
        month: this.$store.getters.getFilterMonthValue,
        reportHeader: value,
      };
      Api.sendPost("nibsa/updateHeaderModifier", params).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.customHeader = value;
            this.reloadTables();
            return true;
          }
        }
      });
    },
    printTables() {
      this.$print(this.$refs["printArea"]);
    },
    exportHTMLToExcel() {
      ExportHTML2Excel(document.getElementById("excelArea"), this.messages.dailyReport.title);
    },
    async makeApiQuery(params) {
      const results = await Api.sendPost("nibsa/dailyReport", params);
      this.currentInvoiceDays = results.data.data.invoiceDays;
      this.currentWorkingDays = results.data.data.workingDays;
      return results.data.data;
    },
    setInitialData(data) {
      this.initValueDaysInvoiced = data.invoiceDays;
      this.initValueWorkingDays = data.workingDays;
      this.initValueCustomColHeader = data.label.trim();
      this.customHeader = data.label.trim();
      this.showBools.customValues = true;
    },
    setTableHeaders(data) {
      const headers = [
        {
          key: "description",
          label: this.messages.dailyReport.tableHeaders.key0,
        },
      ];
      const firstBlock = data.dailyReportTableList[0];
      const detailZone = firstBlock.dailyReportDataList[0].detail;
      const accumDetailZone = firstBlock.dailyReportDataList[0].accumDetail;
      // Refactor
      for (let prop in detailZone) {
        const label =
          prop === "lastFyPurchases"
            ? `FY${data.lastFy % 100}`
            : prop === "targetPurchases" ||
              prop === "monthlyCompletion" ||
              prop === "dailyCompletion"
            ? "CUSTOM__"
            : this.messages.dailyReport.tableHeaders.currentPurchases;
        headers.push({
          key: `detail${prop}`,
          label: label,
        });
      }
      // Refactor
      for (let prop in accumDetailZone) {
        const label =
          prop === "lastFyPurchases"
            ? `FY${data.lastFy % 100}`
            : prop === "targetPurchases" ||
              prop === "monthlyCompletion" ||
              prop === "dailyCompletion"
            ? "CUSTOM__"
            : this.messages.dailyReport.tableHeaders.currentPurchases;
        headers.push({
          key: `accumDetail${prop}`,
          label: label,
        });
      }
      this.tableHeaders = headers;
    },
    processTableData(data, target) {
      //this[target] = data;
      const dataTable = [];
      data.forEach((elem) => {
        const blockName = elem.description !== null ? elem.description : "";
        let productsRows = [],
          dailyAverageRow,
          totalRow;
        if (
          Object.prototype.hasOwnProperty.call(elem, "dailyReportDataList") &&
          elem.dailyReportDataList !== null
        ) {
          productsRows = this.getProductsRows(elem.dailyReportDataList);
          // console.log("dailyReportDataList", productsRows);
        }

        if (
          Object.prototype.hasOwnProperty.call(elem, "total") &&
          elem.total !== null
        ) {
          totalRow = this.getTotalRow(elem.total, blockName);
          // console.log("totalRow", totalRow);
        }

        if (
          Object.prototype.hasOwnProperty.call(elem, "dailyAverage") &&
          elem.dailyAverage !== null
        ) {
          dailyAverageRow = this.getAverageRow(elem.dailyAverage, blockName);
          // console.log("dailyAverageRow", dailyAverageRow);
        }

        if (
          productsRows !== undefined &&
          totalRow !== undefined &&
          dailyAverageRow !== undefined
        ) {
          const block = productsRows.concat(totalRow).concat(dailyAverageRow);
          dataTable.push(block);
        }
      });
      // console.log("dataTable", dataTable);
      this[target] = dataTable.reduce((a, b) => a.concat(b));
      // console.log("this[target]", this[target]);
    },
    getProductsRows(data) {
      const rows = [];
      data.forEach((elem) => {
        elem.detail.empty = "";
        const row = {};
        for (let prop in elem) {
          if (prop === "description") row[prop] = elem[prop];

          if (typeof elem[prop] === "object") {
            const blockName = prop;
            const block = elem[prop];

            for (let prop in block) {
              if (prop !== "empty") {
                const value =
                  block[prop] !== null
                    ? !isNaN(block[prop])
                      ? formatNum.get(block[prop], 2)
                      : ""
                    : "";

                const suffix =
                  (prop === "variation" ||
                    prop === "dailyCompletion" ||
                    prop === "monthlyCompletion") &&
                  value !== ""
                    ? "%"
                    : "";

                row[`${blockName}${prop}`] = `${value}${suffix}`;
              } else {
                row[`${blockName}${prop}`] = block[prop];
              }
            }
          }
        }
        row._classes = "focus";
        row._cellClasses = { empty: "empty-cell" };
        rows.push(row);
      });
      return rows;
    },
    getTotalRow(data, blockName) {
      const row = {};
      for (let prop in data) {
        if (prop === "description") row[prop] = `TOTAL ${blockName}`;
        if (typeof data[prop] === "object") {
          data.detail.empty = "";
          const blockName = prop;
          const block = data[prop];
          for (let prop in block) {
            if (prop !== "empty") {
              const value =
                block[prop] !== null
                  ? !isNaN(block[prop])
                    ? formatNum.get(block[prop], 2)
                    : ""
                  : "";

              const suffix =
                (prop === "variation" ||
                  prop === "dailyCompletion" ||
                  prop === "monthlyCompletion") &&
                value !== ""
                  ? "%"
                  : "";

              row[`${blockName}${prop}`] = `${value}${suffix}`;
            } else {
              row[`${blockName}${prop}`] = block[prop];
            }
          }
        }
      }
      row._classes = "dark-gray-2";
      row._cellClasses = { empty: "empty-cell" };

      return row;
    },
    getAverageRow(data, blockName) {
      const row = {};
      for (let prop in data) {
        if (prop === "description")
          row[
            prop
          ] = `${this.messages.dailyReport.dailyAverage.toUpperCase()} ${blockName}`;
        if (typeof data[prop] === "object") {
          data.detail.empty = "";
          const blockName = prop;
          const block = data[prop];
          for (let prop in block) {
            if (prop !== "empty") {
              const value =
                block[prop] !== null
                  ? !isNaN(block[prop])
                    ? formatNum.get(block[prop], 2)
                    : ""
                  : "";

              const suffix =
                (prop === "variation" ||
                  prop === "dailyCompletion" ||
                  prop === "monthlyCompletion") &&
                value !== ""
                  ? "%"
                  : "";

              row[`${blockName}${prop}`] = `${value}${suffix}`;
            } else {
              row[`${blockName}${prop}`] = block[prop];
            }
          }
        }
      }
      row._classes = "dark-gray";
      return row;
    },
    reloadTables() {
      if (!this.$store.getters.getFiltersAreaComboParams) {
        this.defaultQuery();
      } else {
        this.submitFilterValues();
      }
    },
  },
  created() {
    this.defaultQuery();
  },
};
</script>

<style lang="scss">
.empty-cell {
  background-color: #fff !important;
  border-top-color: #fff !important;
  border-bottom-color: #fff !important;
  border-left-color: #fff !important;
}
.daily-report {
  .table {
    .tbody {
      tr.dark-gray {
        & + .dark-gray {
          background-color: #ddd;
          font-weight: 700;
          & + .dark-gray {
            background-color: #ddd;
            font-weight: 700;
          }
        }
      }
    }
  }
}
@media print {
  body,
  table,
  .table {
    font-size: 9px;

    td {
      padding: 0.2rem;
    }
  }
  button {
    display: none !important;
  }
  .card-header,
  .card-body {
    padding: 0.3rem;
  }
}
.capitalize {
  text-transform: capitalize;
}
</style>
